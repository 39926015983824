import { Broker } from "@amzn/amazonmq-opsconsole-client";
import semver from "semver";

const FEATURE_FLAGS = {
    CONFIG_OVERRIDE: false
}

export function isConfigOverrideSupported(broker: Broker) {
    if (!FEATURE_FLAGS.CONFIG_OVERRIDE) {
        return false;
    }
    const rabbitVersion = broker.brokerInfo.engineVersion;
    if (semver.satisfies(rabbitVersion, ">= 3.12")) {
        return true;
    }
    return false;
}